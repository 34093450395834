import { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getCuponsRequest, getResultsRequest } from 'src/redux/actions';
import { ResultsRequestProps, CoursesProps } from 'src/redux/types/interfaces';
import { useAppDispatch, useAppSelector } from 'src/redux/useAppSelector';
import { ResultState } from 'src/redux/reducers/results';

import { ResultsRender } from './render';
import { Loading } from 'src/components/system/Loading';
import { CoursesState } from 'src/redux/reducers/courses';
import { CuponsState } from 'src/redux/reducers/cupons';
import { cuponsRequestProps } from 'src/redux/sagas/cupons';
import { UserState } from 'src/redux/reducers/user';
import { SimulateState } from 'src/redux/reducers/simulates';

import { ModalContext } from 'src/hooks/useModal';

import { toast } from 'react-toastify';

import moment from 'moment';

import { ReviewArea } from './ReviewArea';

import { arrayCourseCategory } from 'src/shared/utils';
import simulatoroGtm from 'src/shared/simulatoroGtm';

export function Results() {
  let windowContext: any = window;
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const courseGet = localStorage.getItem('courses');
  const token = searchParams.get('token') || localStorage.getItem('token');

  const { setModalChildren, setOpen } = useContext(ModalContext);

  const [percent, setPercent] = useState('');
  const [course, setCourse] = useState<CoursesProps[][]>([[]]);

  const gtmSimulatoro = new simulatoroGtm(windowContext);

  const resultsState: ResultState = useAppSelector(state => state.results);
  const coursesState: CoursesState = useAppSelector(state => state.courses);
  const cuponsState: CuponsState = useAppSelector(state => state.cupons);
  const userState: UserState = useAppSelector(state => state.user);
  const simulatesState: SimulateState = useAppSelector(
    state => state.simulates,
  );

  const {
    user: { user },
  } = userState;

  const courses: CoursesProps[] =
    coursesState.loading === false &&
    Array.isArray(coursesState.courses) &&
    coursesState.courses.length > 0
      ? coursesState.courses
      : JSON.parse(courseGet || '');
  const { loading, result } = resultsState;
  const simulates =
    simulatesState.simulates === null
      ? JSON.parse(localStorage.getItem('simulates') || '{}')
      : simulatesState.simulates;
  const idAula = result[0] !== undefined ? result[0]?.id_aula : 0;
  const currentSimulate =
    simulates?.aulas.find((sim: any) => sim?.id_aula === Number(idAula)) || null;
  const totalQuestions =
    currentSimulate !== null && currentSimulate !== undefined
      ? currentSimulate.questoes_count
      : 0;

  useEffect(() => {
    document.title = 'Resultados | ART - Simulatoro';
  }, []);

  useEffect(() => {
    if (
      userState.loading === false &&
      simulatesState.loading === false &&
      resultsState.loading === false
    ) {
      const dataGtm = {
        event: 'simulatoro_result',
        user_id: user.id_usuario,
        clientFirstName: user.name,
        clientLastName: user.last_name,
        clientemail: user.email,
        clientphone: user.phone,
        course_name: result[0]?.aula.titulo,
        course_category: arrayCourseCategory[simulates.id_course_category - 1],
        cid: simulates?.id_curso,
        fid: simulates.id_course_category,
        test_result: (
          (result[result.length - 1]?.total_acertos / totalQuestions) *
          100
        ).toFixed(0),
        test_duration: result[result.length - 1]?.tempo_prova.slice(0, -3),
      };
      gtmSimulatoro.clear();
      gtmSimulatoro.insert(dataGtm);
    }

    return () => {};
  }, [userState.loading, simulatesState.loading, resultsState.loading]);

  useEffect(() => {
    if (state === null) {
      navigate(`/`);
      return;
    }

    const { idCurso, testId } = state;

    if (
      courses.filter(
        (myCourse: any) => String(myCourse[0]?.id_curso) === String(idCurso),
      ) !== undefined
    ) {
      const courseSet = courses.filter(
        (myCourse: any) => String(myCourse[0]?.id_curso) === String(idCurso),
      ) as any;
      setCourse(courseSet);
    }

    var today = new Date();

    const dateSend = moment(today).format('YYYY-MM-DD');

    const cuponsData: cuponsRequestProps = {
      token: token || '',
      course: idCurso,
      date: dateSend,
      curso_cupom_simulatoro: simulatesState?.simulates?.curso_cupom_simulatoro || null,
    };

    dispatch(getCuponsRequest(cuponsData));

    const requestProps: ResultsRequestProps = {
      token: token || '',
      id_curso: String(idCurso),
      id_turma: String(testId),
    };

    setTimeout(() => {
      dispatch(getResultsRequest(requestProps));
    }, 1000);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const percentSet = (
      (result[result.length - 1]?.total_acertos / totalQuestions) *
      100
    ).toFixed(0);
    setPercent(percentSet);
  }, [result]);

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }

    toast.success('Copiado com sucesso!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    const dataGtm = {
      event: 'toro_click',
      user_id: user.id_usuario,
      clientFirstName: user.name,
      clientLastName: user.last_name,
      clientemail: user.email,
      clientphone: user.phone,
      course_name: result[0]?.aula.titulo,
      course_category: arrayCourseCategory[simulates.id_course_category - 1],
      cid: simulates?.id_curso,
      fid: simulates.id_course_category,
      feature_name: 'copiar cupom',
    };
    gtmSimulatoro.clear();
    gtmSimulatoro.insert(dataGtm);

    return;
  }

  function handleOpenReviewModal() {
    setModalChildren(
      <ReviewArea idResults={result[result?.length - 1]?.id_prova} />,
    );
    setOpen(true);
    return;
  }

  function onOpenOtherResults(percent: number, timeTest: string) {
    const dataGtm = {
      event: 'toro_click',
      user_id: user.id_usuario,
      clientFirstName: user.name,
      clientLastName: user.last_name,
      clientemail: user.email,
      clientphone: user.phone,
      course_name: result[0]?.aula.titulo,
      course_category: arrayCourseCategory[simulates.id_course_category - 1],
      cid: simulates?.id_curso,
      fid: simulates.id_course_category,
      test_result: percent,
      test_duration: timeTest,
      feature_name: 'Abrir resultado',
    };
    gtmSimulatoro.clear();
    gtmSimulatoro.insert(dataGtm);
  }

  if (
    !loading &&
    Array.isArray(result) &&
    result?.length > 0 &&
    !cuponsState.loading
  )
    return (
      <ResultsRender
        copyTextToClipboard={copyTextToClipboard}
        result={result}
        percent={percent}
        total_acertos={String(result[result.length - 1]?.total_acertos)}
        tempo_gasto={result[result.length - 1]?.tempo_prova.slice(0, -3)}
        course={course[0]}
        handleOpenReviewModal={handleOpenReviewModal}
        totalQuestions={totalQuestions}
        onOpenOtherResults={onOpenOtherResults}
      />
    );
  else return <Loading />;
}
