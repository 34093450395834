import { useEffect, useState, useContext } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import moment from 'moment';

import { TimerContext } from 'src/hooks/useTimer';

import { SimulateTestsRender } from './render';

import { ModalQuestion } from 'src/components/modal/ModalQuestion';
import { ModalFinish } from 'src/components/modal/ModalFinish';
import { SidebarContext } from 'src/components/system/Sidebar';
import { ModalFinishWithoutAnsered } from 'src/components/modal/ModalFinishWithoutAnsered';

import { ModalContext } from 'src/hooks/useModal';
import {
  QuestionsProps,
  SimulateTestRequestProps,
  SimulateTestsType,
  SimulateAnswerProps,
  SimulateSendDataProps,
} from 'src/redux/types/interfaces';
import { useAppDispatch, useAppSelector } from 'src/redux/useAppSelector';
import { SimulateTestsStates } from 'src/redux/reducers/simulateTests';
import {
  getSimulateTestsRequest,
  sendSimulateTestDataRequest,
  setRdStationRequest,
  setSimulateAnswerRequest,
} from 'src/redux/actions';
import { SimulateState } from 'src/redux/reducers/simulates';

export function SimulateTests() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('token');
  const { setStartTestSidebar } = useContext(SidebarContext);
  const simulateTestsState: SimulateTestsStates = useAppSelector(
    state => state.simulateTests,
  );
  const simulateState: SimulateState = useAppSelector(state => state.simulates);

  const simulates =
    simulateState.simulates === null
      ? JSON.parse(localStorage.getItem('simulates') || '')
      : simulateState.simulates;

  const params = useParams();
  const { testId, idCurso } = params;

  const screenName =
    simulates.aulas.find((simulate: any) => simulate?.id_aula == testId)
      ?.titulo || '';

  const { traffic_campaign, traffic_medium, traffic_value, traffic_source } =
    useAppSelector(state => state.traffic);

  const rdStationRequestProps = {
    token: token || '',
    body: {
      traffic_medium: !traffic_medium ? 'cpc' : traffic_medium,
      traffic_campaign: !traffic_campaign ? 'easter-50-off' : traffic_campaign,
      traffic_value: !traffic_value ? 'easter eggs' : traffic_value,
      traffic_source: !traffic_source ? 'Google' : traffic_source,
      conversion_identifier: screenName,
      tags: screenName,
    },
  };

  const navigate = useNavigate();

  const [startTest, setStartTest] = useState(true);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [questions, setQuestions] = useState<QuestionsProps[]>([]);

  const { setRunning, setTime, time } = useContext(TimerContext);
  const { setModalChildren, setOpen } = useContext(ModalContext);

  useEffect(() => {
    document.title = screenName + ' | ART - Simulatoro';
  }, []);

  useEffect(() => {
    if (Object.keys(params).length === 0) navigate(`/`);

    const requestProps: SimulateTestRequestProps = {
      token: token || '',
      id_simulado: String(testId),
    };

    dispatch(getSimulateTestsRequest(requestProps));

    setStartTestSidebar(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      Array.isArray(simulateTestsState.questions) &&
      simulateTestsState.questions.length > 0
    ) {
      const { questions }: SimulateTestsType = simulateTestsState;
      let questionsSend = questions;
      questionsSend.map((q: QuestionsProps) => {
        q.answereds = [];
      });
      setQuestions(questionsSend);
    }
  }, [simulateTestsState.loading]);

  function handleStartTest() {
    setStartTest(false);
    setTime(0);
    setRunning(true);

    dispatch(setRdStationRequest(rdStationRequestProps));
  }

  function handleFinishSimulate() {
    if (
      questions?.filter(question => question.answereds.length === 0).length !==
      0
    ) {
      setModalChildren(<ModalFinishWithoutAnsered />);
      setOpen(true);
      return;
    }

    setTime(0);
    const timeNow =
      ('0' + parseInt(String((time / 3600) % 3600))).slice(-2) +
      ':' +
      ('0' + parseInt(String((time / 60) % 60))).slice(-2) +
      ':' +
      ('0' + (time % 60)).slice(-2);

    let questionsSend = '{';
    let answersWithLettersSend: any = [];
    let checkedQuestions = false;

    questions?.map(question => {
      if (question.answereds.length > 0) {
        questionsSend =
          questionsSend +
          `"${question.id_questao}": "${question.answereds[0].answer}",`;
        answersWithLettersSend.push({
          answer: question.answereds[0].answer,
          questionId: question.id_questao,
          letter: String.fromCharCode(
            question.answers.findIndex(
              answer => answer.id_resposta === question.answereds[0].answer,
            ) + 65,
          ),
        });
        checkedQuestions = true;
      }
    });
    questionsSend = questionsSend.slice(0, -1) + '}';

    const sendFinish = {
      prova: {
        contador: String(timeNow),
        curso: simulateState?.simulates?.tag,
        aula: testId,
        question: JSON.parse(checkedQuestions ? questionsSend : '{}'),
        answersWithLetters: answersWithLettersSend,
        data_prova: moment().format('YYYY/mm/DD hh:mm'),
      },
    };

    const requestProps: SimulateSendDataProps = {
      token: token || '',
      data: sendFinish,
      id_aluno: String(simulateState.simulates?.id_usuario),
      id_simulado: String(testId),
    };

    dispatch(sendSimulateTestDataRequest(requestProps));

    setStartTestSidebar(false);
    let rdStationSendFinish = {
      ...rdStationRequestProps,
    };
    rdStationSendFinish.body.tags = `Finalizou Simulado: ${rdStationSendFinish.body.tags}`;
    dispatch(setRdStationRequest(rdStationRequestProps));

    navigate('/results', {
      state: { verify: true, idCurso: idCurso, testId: testId },
    });
  }

  function handlePassQuestion() {
    if (questions !== null && questionNumber < questions.length - 1)
      setQuestionNumber(questionNumber + 1);

    if (questionNumber === questions.length - 1) {
      setModalChildren(
        <ModalFinish handleFinishSimulate={handleFinishSimulate} />,
      );
      setRunning(false);
      setOpen(true);
    }
  }

  function handleBackQuestion() {
    if (questionNumber > 0) setQuestionNumber(questionNumber - 1);
  }

  function handleSelectAnswer(
    id_questao: number,
    id_resposta: number, //id resposta
    questionNumber: number,
  ) {
    let questionsUpdate = questions;
    const timeNow =
      ('0' + parseInt(String((time / 3600) % 3600))).slice(-2) +
      ':' +
      ('0' + parseInt(String((time / 60) % 60))).slice(-2) +
      ':' +
      ('0' + (time % 60)).slice(-2);

    let answeredssend: string;

    if (id_questao === 0 && id_resposta === 0) {
      answeredssend = `{
        "contador": "${String(timeNow)}",
        "curso": "${simulateState?.simulates?.tag}",
        "aula": "${testId}",
        "question_time": {},
        "question": {}
      }`;
    } else {
      answeredssend = `{
        "contador": "${String(timeNow)}",
        "curso": "${simulateState?.simulates?.tag}",
        "aula": "${testId}",
        "question_time": {
          "${id_questao}": "${String(timeNow)}"
        },
        "question": {
          "${id_questao}": "${String(id_resposta)}"
        }
      }`;
    }

    const requestProps: SimulateAnswerProps = {
      token: token || '',
      id_simulado: String(testId),
      data: JSON.parse(answeredssend),
      id_usuario: String(simulateState.simulates?.id_usuario),
    };

    dispatch(setSimulateAnswerRequest(requestProps));

    const answereds = [
      {
        question: id_questao,
        answer: id_resposta,
        elapsed: String(timeNow),
      },
    ];

    questionsUpdate[questionNumber].answereds = answereds;

    setQuestions(questionsUpdate);
  }

  function handleOpenComentedQuestion() {
    setRunning(false);
    setModalChildren(
      <ModalQuestion
        questionNumber={questionNumber + 1}
        simulateName={screenName}
        //link={questions[questionNumber]?.video_embed}
      />,
    );
    setOpen(true);
  }

  function handleSelectQuestion(value: number) {
    setQuestionNumber(value);
  }

  return (
    <SimulateTestsRender
      screenName={screenName}
      urlGoBack={`/simulates/${idCurso}`}
      startTest={startTest}
      handleStartTest={handleStartTest}
      questionNumber={questionNumber}
      handlePassQuestion={handlePassQuestion}
      handleBackQuestion={handleBackQuestion}
      questions={questions}
      handleSelectAnswer={handleSelectAnswer}
      handleOpenComentedQuestion={handleOpenComentedQuestion}
      handleSelectQuestion={handleSelectQuestion}
    />
  );
}
