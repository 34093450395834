import { useContext, useEffect, useState } from 'react';

import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { Loading } from 'src/components/system/Loading';
import { SidebarContext } from 'src/components/system/Sidebar';

import { getSimulateRequest } from 'src/redux/actions';
import { CoursesState } from 'src/redux/reducers/courses';
import { SimulateState } from 'src/redux/reducers/simulates';
import { SimulateRequestProps } from 'src/redux/types/interfaces';
import { useAppDispatch, useAppSelector } from 'src/redux/useAppSelector';

import { SimulatesRender } from './render';

export function Simulates() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('token');
  const { setStartTestSidebar } = useContext(SidebarContext);
  const simulatesState: SimulateState = useAppSelector(
    state => state.simulates,
  );

  const { simulates, loading } = simulatesState;

  const params = useParams();
  const { idCurso } = params;

  const coursesState: CoursesState = useAppSelector(state => state.courses);
  const course = coursesState.courses.find(
    course => String(course?.id_curso) === idCurso,
  );

  const navigate = useNavigate();

  const [pageName, setPageName] = useState('');

  if (simulates?.aulas?.length === 0) {
    navigate('/');
  }

  useEffect(() => {
    document.title = 'Simulados | ART - Simulatoro';
  }, []);

  useEffect(() => {
    if (Object.keys(params).length === 0) navigate('/');

    setPageName(course?.nome || '');

    const requestProps: SimulateRequestProps = {
      token: token || '',
      id_curso: String(idCurso),
    };

    setStartTestSidebar(false);
    dispatch(getSimulateRequest(requestProps));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (simulates?.nome !== undefined)
      localStorage.setItem('simulates', JSON.stringify(simulates));
  }, [simulates?.nome]);

  if (loading || simulates === null) return <Loading />;
  else
    return (
      <SimulatesRender
        pageName={pageName}
        aulas={simulates?.aulas}
        handleGoBack={() => navigate('/')}
      />
    );
}
